import React from 'react';
import "./BackgroundGraphics.scss";

const BackgroundGraphics = ({children}) => {

  return (
    <div id="content-wrapper" className="content-wrapper background-graphics">
      {children}
    </div>
  )
}

export default BackgroundGraphics