/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import classNames from "classnames";
import Button from "Accessories/Button/Button";
import { ReactComponent as SvgIconClose } from "static/assets/brand/icons/Close.svg";
import { ReactComponent as SvgLogoSmall } from "static/assets/brand/logo/mobile/Juto_logosmall.svg";
import "./Navigation.scss";

const TopNavigation = (props) => {

  const data = useStaticQuery(graphql`
    query MenuQuery {
      menus: allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            items {
              title
              object_slug
            }
          }
        }
      }
      optionsPage: allWordpressAcfOptions {
        edges {
          node {
            options {
              news {
                news_items {
                  news_item
                }
              }
            }
          }
        }
      }
    }
  `)

  const [menuOverlay, setMenuOverlay] = useState(false);
  const { optionsPage } = data;
  const { title } = props;

  useEffect(
    () => {
      const className = 'modal-open';
      const element = window.document.body;
      if (menuOverlay) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
      return function cleanup() {
        element.classList.remove(className);
      };
    },
    [menuOverlay] // Only re-call effect when value changes
  );

  const handleCloseClick = () => {
    setMenuOverlay(false)
  }

  const handleMenuClick = () => {
    setMenuOverlay(true)
  }

  const renderMenuOverlay = () => {

    const { menus } = data;
    const mainMenu = menus.edges[1];

    return (
      <div
        id="menu-overlay"
        className={classNames(menuOverlay && "is-open", "menu-overlay")}
      >
        <div id="menu-column-wrapper" className="menu-column-wrapper column-wrapper d-flex flex-column flex-md-row justify-content-start">
          <Button
            className="btn-menu-close"
            onClick={handleCloseClick}
            text={<SvgIconClose />}
          />
          <nav className="d-flex flex-column primary-menu position-relative">
            <ul>
              {
                mainMenu.node.items.map(menuItem => {
                  const titleLettersArray = menuItem.title.split("")
                  return (
                    <li key={menuItem.object_slug} className="text-lg nav-level-1-item position-absolute">
                      <Link
                        to={`/${menuItem.object_slug}/`}
                        className="menu-primary text-link text-transform-hover"
                        activeClassName="is-active"
                        onClick={handleCloseClick}
                      >
                        {
                          titleLettersArray.map((letter, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <span key={`${letter} ${index}`} className={`text-menu letter-${index + 1}`}>{letter}</span>
                          ))
                        }
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className="header">
      <div className="navigation d-flex justify-content-between">
        <Link
          to="/"
          className="logo"
          activeClassName="is-active"
        >
          <SvgLogoSmall />
        </Link>
        {
          title && (
            <div className="text-logo">
              <Link to="/">{title}</Link>
            </div>
          )
        }
        {
          optionsPage.edges[0].node.options.news && (
            <marquee
              direction=""
            >
              {
                optionsPage.edges[0].node.options.news.news_items.map(item => (
                  <span key={item.news_item}>{item.news_item}</span>
                ))
              }
            </marquee>
          )
        }
        <div className="menu-btn-container d-flex">
          <Button
            className="menu-btn"
            onClick={handleMenuClick}
            text="Menü"
          />
        </div>
      </div>
      {renderMenuOverlay()}
    </div>
  );
}

export default TopNavigation