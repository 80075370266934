import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';
import CookieConsent, { Cookies } from "react-cookie-consent";
import BackgroundGraphics from "Accessories/BackgroundGraphics/BackgroundGraphics";
import TopNavigation from "Layout/Navigation/Navigation";
import { ReactComponent as SvgIconCookie } from "static/assets/brand/icons/Juto_cookie.svg";
import config from "../../../data/SiteConfig";
import "./style.scss";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
});

const MainLayout = (props) => {
  const [hasConsent, setHasConsent] = useState(Cookies.get("JuToChocolateCookie") || false);
  const { children, location } = props;

  const titleize = (slug) => {
    const words = slug.split("-");
      return words.map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      }).join(' ');
  }

  const getLocalTitle = () => {
    const pathPrefix = config.pathPrefix ? config.pathPrefix : "/";
    const currentPath = location.pathname
    .replace(pathPrefix, "")
    .replace("/", "")
    const title = currentPath === "" ? "Home" : titleize(currentPath)
    return title;
  }

  const handleTouch = () => {
    document.body.classList.add("is-touch-device");
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("touchstart", handleTouch);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.removeEventListener("touchstart", handleTouch);
      }
    };
  });

  return (
    <JssProvider generateClassName={generateClassName}>
      <>
        <Helmet
          title={`${getLocalTitle()} | ${config.siteTitle}`}
          meta={[
            {
              name: 'description',
              content: config.siteDescription,
            },
            {
              name: 'image',
              content: config.siteSeoDefaultImage,
            },
            {
              property: 'og:title',
              content: config.siteTitle,
            },
            {
              property: 'og:description',
              content: config.siteDescription,
            },
            {
              property: 'og:image',
              content: config.siteSeoDefaultImage,
            },
            {
              property: 'og:url',
              content: config.siteUrl,
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              property: 'og:site_name',
              content: config.siteTitle,
            },
            {
              name: 'twitter:image:alt',
              content: config.siteDescription,
            }
          ]}
        >
          {hasConsent && <script async src="https://www.googletagmanager.com/gtag/js?id=G-SG1PZW5ZY1" />}
          {hasConsent && (
            <script>
              {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
  
              gtag('config', 'G-SG1PZW5ZY1');`}
            </script>
          )}
        </Helmet>
        <BackgroundGraphics>
          <TopNavigation location={location} title={config.siteTitle} />
          {children}
        </BackgroundGraphics>
        <CookieConsent
          location="bottom"
          buttonText="Einverstanden"
          cookieName="JuToChocolateCookie"
          expires={150}
          onAccept={() => setHasConsent(true)}
          onDecline={() => setHasConsent(false)}
        >
          <div className="cookie-content d-flex">
            <SvgIconCookie />
            <div className="cookie-text">
              <h3>Wir benutzen Cookies</h3>
              <span>Hier gehts zu den </span>
              <Link
                to="/datenschutz/"
                className="text-link"
                activeClassName="is-active"
              >
                Datenschutzrichtlinien
              </Link>
            </div>
          </div>
        </CookieConsent>
      </>
    </JssProvider>
  );
}

export default MainLayout
