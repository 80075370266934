module.exports = {
  siteTitle: "Jux und Tollerei", // Site title.
  siteTitleAlt: "", // Alternative site title for SEO.
  siteBrand: "JuTo", // Site brand.
  mapsApiKey: "AIzaSyCgLDAbCsNfSTKcdfYyNDCAaW_u8vz74Ww",
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteSeoDefaultImage: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://juto.netlify.com/", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Münchens großer Indoorspielplatz zum Kraxeln und Austoben", // Website description used for RSS feeds/meta description tag.
  postDefaultCategoryID: "Design" // Default category for posts.
};
